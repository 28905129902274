// Labels Dashboard
.labels-dashboard {
  cursor: pointer;
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1);
  border-width: 0px;
  border-radius: 14px;
  transition: all 0.5s;

  h2 {
    transition: all 0.5s;
  }

  & .icon-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & .icon-badge {
      height: 90px;
      width: 90px;
      justify-content: center;
      align-items: center;
      display: flex;
      padding: 1rem;
      border: 1px solid #000;
      border-radius: 100%;
      transition: all 0.5s;

      & .material-icons {
        font-size: 50px;
        transition: all 0.5s;
      }
    }
  }

  &:hover {
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
    background-color: #f1f1f1;
    background-image: linear-gradient(45deg, $gray 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    transition: background 750ms ease-in-out;

    .icon-badge,
    .material-icons,
    h2 {
      border-color: $primary;
      color: $primary;
      transition: all 0.5s;
    }
  }
  transition: all 0.5s;
}
