.btn {
  &.btn-call-to-action {
    font-size: 12px;
    line-height: 50px;
    font-weight: 600;
    float: right;
    padding: 0.2rem 3.5rem;
    background-color: $primary;
    border: 1px solid $primary;
    color: #fefefe;
    border-radius: 0px;
    background-image: linear-gradient(45deg, $darkPrimary 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    transition: background 750ms ease-in-out;
    transition: 0.7s;

    &.signup {
      background-color: #000;
      color: white;
      border: 1px solid #000;
    }

    &:hover,
    &:active,
    &:focus-visible {
      border: 1px solid $darkPrimary !important;
      background-position: 0;
      background-color: $darkPrimary !important;
      transition: 0.7s;
      color: #fefefe;
    }

    &:disabled {
      background-color: $lightPrimary;
      border-color: $lightPrimary;
      color: $darkPrimary;
      transition: 0.7s;
    }

    &:focus-visible {
      box-shadow: 0 0 0.25rem rgba(0, 26, 31, 0.5);
    }
  }
}

.appointment-btn {
  button.btn-call-to-action {
    background-color: $tertiary;
    border: 1px solid $tertiary;
    color: #fefefe;
    border-radius: 0px;
    background-image: linear-gradient(
      45deg,
      $darkTertiary 50%,
      transparent 50%
    );
  }
}
