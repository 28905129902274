.section-height-100-center {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Content
.bg-sess-1 {
  background-image: linear-gradient(
      to right,
      rgba(130, 211, 182, 0.8) 70%,
      rgba(0, 0, 0, 0.3) 100%
    ),
    url("../img/background-sess1.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-color-primary-left {
  background: linear-gradient(
    to left,
    rgba(130, 211, 182, 0.8) 70%,
    rgba(0, 0, 0, 0.1) 100%
  );
}
.bg-color-primary-right {
  background: linear-gradient(
    to right,
    rgba(130, 211, 182, 0.8) 70%,
    rgba(0, 0, 0, 0.1) 100%
  );
}

.bg-sess-2 {
  background-image: linear-gradient(
      rgba(130, 211, 182, 0.8),
      rgba(0, 0, 0, 0.8)
    ),
    url("../img/background-sess2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

// Footer

.footer {
  & img {
    height: 150px;
    transition: 0.3s;

    &:hover {
      transform: scale(1.1);
      transition: 0.3s;
    }
  }
}

.social-media {
  position: relative;
  color: #fff;
  font-size: 26px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 50%;
}

.social-media:hover {
  & a {
    color: $primary !important;
    transition: 0.4s;
  }
  border: 1px solid $secondary !important;
  transition: 0.8s;
}

.social-media a {
  color: #fff !important;
}

.card-lgpd {
  bottom: 5px;
  display: flex;
  justify-content: center;
  & .card {
    padding: 20px;
    width: 70%;
    box-shadow: rgb(170 170 170) 0px 0px 10px;
    border: 0px;
  }
}
