.navbar-insti {
  background-color: transparent !important;
  transition: background 0.7s;

  &.bg-color-dark-primary {
    background: $darkTertiary !important;
    transition: background 0.7s;
  }

  &.nav-menu .nav-link {
    font-size: 13px;
    color: #fff !important;
    font-weight: 600;
    margin: auto 12px;
  }

  &.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }

  & .navbar-brand {
    & img {
      width: 80%;
      animation: slide-right 0.6s both;
    }
  }
}

@keyframes slide-right {
  0% {
    transform: translateX(-50px);
  }
  100% {
    transform: translateX(0px);
  }
}
